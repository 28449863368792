import { createI18n, DefaultLocaleMessageSchema, LocaleMessages } from 'vue-i18n'
import { getStorage } from '@/helper/storage.helper';
/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<DefaultLocaleMessageSchema> {
  const locales = require.context(
    "../../locales",
    true,
    /[A-Za-z0-9-_,\s]+\.ts$/i
  );
  const messages: LocaleMessages<DefaultLocaleMessageSchema> = {}
  locales.keys().forEach(key => {
    const match = key.split(/[./]/);

    if (match && match.length > 1) {
      messages[match[2]] = {
        ...messages[match[2]],
        [match[3]]: locales(key).default,
      };
    }
  })
  return messages
}

const i18n = createI18n({
  locale: getStorage("lang") || "en",
  fallbackLocale: "th",
  messages: loadLocaleMessages(),
})

export default i18n;