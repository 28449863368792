
import CustomModal from "@/components/atoms/Modal.vue";
import AtomButton from "@/components/atoms/CustomButton.vue";

import { defineComponent } from 'vue';
import { mapActions } from "vuex";
import { getStorage } from '@/helper/storage.helper';

export default defineComponent({
  name: "ModalBroadcast",
  components: { AtomButton, CustomModal },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    isHtml: {
      type: Boolean,
      required: false,
      default: false,
    },
    ButtonStyle: {
      type: String,
      required: false,
      default: "width: 100%;",
    },
    ButtonTitle: {
      type: String,
      required: false,
      default: "",
    },
    ButtonDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    popUpType: {
      type: String,
      required: false,
      default: null,
    },
    customStyleModal: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      isWarning: false,
      isSuccess: false,
    }
  }, 
  computed: {
    isThemeDark(): boolean {
      if (this.$store.state.theme.currentTheme === "dark") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions("upload", ["fetchDeletDirectoryResult"]),
    async restoreState(): Promise<void> {
      const filePath = getStorage("filePath");

      if(filePath !== null) {
        await this.fetchDeletDirectoryResult();
      }

      window.location.href = "/";
    }
  },
});
