
import { defineComponent } from 'vue';
import Navbar from '@/components/molecules/Navbar.vue';

export default defineComponent({
  name: "LoadingScreen",
  components: {
    Navbar
  },
  computed: {
    isThemeDark(): boolean {
      if (this.$store.state.theme.currentTheme === "dark") {
        return true;
      } else {
        return false;
      }
    },
  },
});
