export default {
  title: 'G2Viz',
  description: 'is a platform for visualizing and analyzing GTFS data',
  about: {
    button: 'About G2Viz',
    title: 'About G2Viz and Data Policy',
  },

  start: 'Let\'s get started on',
  upload: 'upload your GTFS data',

  conventionTitle: 'System Conventions',
  convention1: '1. The maximum file size is limited to 10MB.',
  convention2: '2. GTFS dataset must be compressed as a .zip file.',
  convention3: '3. GTFS zip file must contain the following files.',
  moreDetail: 'more details about GTFS dataset',

  dragAndDrop: 'Drag and drop file here',
  browse: 'Browse File',

  select: 'or select from our list',
  selectCoutry: 'select country',
  selectCity: 'select city',

  feedback: 'We\'d love to hear your feedback.',

  contact: {
    title: "contact us :"
  }
}