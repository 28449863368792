import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1fba2d6f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "molecule-modal--background" }
const _hoisted_3 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["modal", {'dark' : _ctx.isThemeDark , 'out' : _ctx.isModalClosed }]),
        style: _normalizeStyle(_ctx.customStyleModal)
      }, [
        _createElementVNode("header", {
          class: _normalizeClass(["modal-header", _ctx.isThemeDark ? 'dark': ''])
        }, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["line-break", _ctx.isThemeDark ? 'dark': ''])
        }, null, 2),
        _createElementVNode("section", {
          class: _normalizeClass(["modal-body", _ctx.isThemeDark ? 'dark': ''])
        }, [
          _renderSlot(_ctx.$slots, "body", {}, undefined, true)
        ], 2),
        _createElementVNode("footer", _hoisted_3, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ])
      ], 6)
    ])
  ]))
}