import { Request } from '@/utils/request';
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { URL_G2VIZ_SERVICES } from '@/constants/config';
import { routesPath } from '@/utils/constant/path';

class Mobility extends Request {
  constructor() {
    super(URL_G2VIZ_SERVICES);
  }

  async getJsonUrl(url: string, method: Method = "GET", data = {}): Promise<any> {
    const res = await this.requestWithAxios
      .request({
        url,
        data,
        method
      });
    return res;
  }

  async fetchRoutes(filePath: string) : Promise<any> {
    const requestPath = routesPath.base+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 

  async fetchRouteDetails(filePath: string) : Promise<any> {
    const requestPath = routesPath.base+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 
}

export default new Mobility();