import { routerConfig } from "@/constants/router";
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: routerConfig.home.path,
    name: routerConfig.home.name,
    component: () => import('../views/Home.vue')
  },
  {
    path: routerConfig.visualize.path,
    name: routerConfig.visualize.name,
    component: () => import('../views/Content.vue'),
    beforeEnter: (to, from) => {
      if (!store.getters['upload/getStateAccessible'] || from.name !== 'Home') {
        return routerConfig.home.path;
      }
    },
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/Landing.vue') 
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
export default router