import { Request } from '@/utils/request';
import { Method } from "axios";
import { URL_G2VIZ_SERVICES } from '@/constants/config';
import { uploadPath } from '@/utils/constant/path';

class Upload extends Request {
  constructor() {
    super(URL_G2VIZ_SERVICES);
  }

  async getJsonUrl(url: string, method: Method = "GET", data = {}): Promise<any> {
    const res = await this.requestWithAxios
      .request({
        url,
        data,
        method,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    return res;
  }

  async uploadFile(data: any) : Promise<any> {
    return await this.getJsonUrl(
      uploadPath.upload,
      "POST",
      data,
    );
  } 

  async uploadFileChunk(data: any) : Promise<any> {
    return await this.getJsonUrl(
      uploadPath.uploadChunk,
      "POST",
      data,
    );
  } 

  async reuploadFileChunk(data: any) : Promise<any> {
    return await this.getJsonUrl(
      uploadPath.reupload.chunk,
      "POST",
      data,
    );
  } 

  async reuploadFile(data: any) : Promise<any> {
    switch(data.filePath){
      case 'stops.txt': 
        return await this.getJsonUrl(uploadPath.reupload.stops, "POST", data.uploadedFile);
        break;
      case 'routes.txt': 
        return await this.getJsonUrl(uploadPath.reupload.routes, "POST", data.uploadedFile);
        break;
      case 'trips.txt': 
        return await this.getJsonUrl(uploadPath.reupload.trips, "POST", data.uploadedFile);
        break;
      case 'stop_times.txt': 
        return await this.getJsonUrl(uploadPath.reupload.stop_times, "POST", data.uploadedFile);
        break;
      case 'calendar.txt': 
        return await this.getJsonUrl(uploadPath.reupload.calendar, "POST", data.uploadedFile);
        break;
      case 'calendar_dates.txt': 
        return await this.getJsonUrl(uploadPath.reupload.calendar_dates, "POST", data.uploadedFile);
        break;
      case 'shapes.txt': 
        return await this.getJsonUrl(uploadPath.reupload.shapes, "POST", data.uploadedFile);
        break;
    }
  }

  async fetchUploadStatus(filePath: string) : Promise<any> {
    const requestPath = uploadPath.uploadStatus+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 

  async fetchReuploadStatus(filePath: string) : Promise<any> {
    const requestPath = uploadPath.reupload.status+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 

  async deleteDirectory(filePath: string) : Promise<any> {
    const requestPath = uploadPath.deleteDir+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "DELETE",
    )
  }

}

export default new Upload();