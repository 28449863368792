import Analytics from "@/services/Analytics";
import { ActionContext } from "vuex";
import { RootState } from "@/store";
import { speedData, serviceRateData, headwayData } from "@/constants/type/analytics";

const defaultState = () => ({
  isServiceRateFetched: false,
  isSpeedFetched: false,
  isSpeedError: false,
  isHeadwayFetched: false,
  isHeadwayError: false,
  currentSpeedData: {} as speedData,
  currentServiceRateData: {} as serviceRateData,
  currentHeadwayData: {} as headwayData,
});

const state = defaultState();

export type AnalyticsState = typeof state;

const mutations = {
  SET_IS_SERVICE_RATE_FETCHED(state: AnalyticsState, payload: boolean): void {
    state.isServiceRateFetched = payload;
  },

  SET_IS_SPEED_FETCHED(state: AnalyticsState, payload: boolean): void {
    state.isSpeedFetched = payload;
  },

  SET_IS_HEADWAY_FETCHED(state: AnalyticsState, payload: boolean): void {
    state.isHeadwayFetched = payload;
  },

  SET_IS_SPEED_ERROR(state: AnalyticsState, payload: boolean): void {
    state.isSpeedError = payload;
  },

  SET_IS_HEADWAY_ERROR(state: AnalyticsState, payload: boolean): void {
    state.isHeadwayError = payload;
  },

  SET_CURRENT_SERVICE_RATE_DATA(state: AnalyticsState, payload: serviceRateData): void {
    state.currentServiceRateData = payload;
  },

  SET_CURRENT_SPEED_DATA(state: AnalyticsState, payload: speedData): void {
    state.currentSpeedData = payload;
  },

  SET_CURRENT_HEADWAY_DATA(state: AnalyticsState, payload: headwayData): void {
    state.currentHeadwayData = payload;
  },

  RESET_FETCHED_DATA(state: AnalyticsState): void {
    state.isServiceRateFetched = false;
    state.isSpeedFetched = false;
    state.isHeadwayFetched = false;
    state.isHeadwayError = false;
  },
};

const actions = {
  async fetchServiceRateResult({ commit, rootState }: ActionContext<PermissionState, RootState>): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const dateStr = rootState.mobility.currentDate;
      const requestPath = filePath+"/"+dateStr;
  
      const res = await Analytics.fetchServiceRate(requestPath);
      const tempServiceRate = res.data.payload;
  
      const initialData = {
        min: tempServiceRate.initialData.minServiceRate,
        max: tempServiceRate.initialData.maxServiceRate,
        peakTime: tempServiceRate.initialData.peakTime,
        peakNumber: tempServiceRate.initialData.peakNumber,
      };
      
      let serviceRateData = {} as serviceRateData;    
      serviceRateData.initialData = initialData;
      serviceRateData.payloadData = tempServiceRate.serviceRateData;
      serviceRateData.hourlyData = tempServiceRate.hourlyServiceRateData;
  
      commit("SET_CURRENT_SERVICE_RATE_DATA", serviceRateData);
      commit("SET_IS_SERVICE_RATE_FETCHED", true);
  
      return serviceRateData;

    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchSpeedResult({ commit, rootState }: ActionContext<PermissionState, RootState>): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const dateStr = rootState.mobility.currentDate;
      const measurement = rootState.upload.measurement;
      const requestPath = filePath+"/"+dateStr+"/"+measurement;  
  
  
      const res = await Analytics.fetchSpeed(requestPath);
  
      if(res.data.status === "success") {
        const tempSpeed = res.data.payload;
    
        const initialData = {
          min: tempSpeed.initialData.minSpeed,
          max: tempSpeed.initialData.maxSpeed
        };
    
        let speedData = {} as speedData;
        speedData.initialData = initialData;
        speedData.payloadData = tempSpeed.speedData;
        speedData.hourlyData = tempSpeed.hourlySpeedData;
    
        commit("SET_CURRENT_SPEED_DATA", speedData);
        commit("SET_IS_SPEED_FETCHED", true);
    
        return speedData;
  
      } else {
        const errorMessage = res.data?.error[0]?.payload?.missingColumn;
  
        commit("SET_IS_SPEED_ERROR", true);
        commit("SET_IS_SPEED_FETCHED", true);
  
        return errorMessage;
      }
    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchHeadwayResult({ commit, rootState }: ActionContext<PermissionState, RootState>): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const dateStr = rootState.mobility.currentDate;
      const requestPath = filePath+"/"+dateStr;
  
      const res = await Analytics.fetchHeadway(requestPath);

      if(res.data.status === "success") {
        const tempHeadway = res.data.payload;
  
        const initialData = {
          min: tempHeadway.initialData.minHeadway,
          max: tempHeadway.initialData.maxHeadway
        };
    
        let headwayData = {} as headwayData;
        headwayData.initialData = initialData;
        headwayData.payloadData = tempHeadway.headwayData;
        headwayData.hourlyData = tempHeadway.hourlyHeadwayData;
    
        commit("SET_CURRENT_HEADWAY_DATA", headwayData);
        commit("SET_IS_HEADWAY_FETCHED", true);
    
        return headwayData;

      } else {
        const errorMessage = res.data?.error[0]?.payload?.error;
  
        commit("SET_IS_HEADWAY_ERROR", true);
        commit("SET_IS_HEADWAY_FETCHED", true);
  
        return errorMessage;
      }
    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  }
};

const getters = {
  isSpeedError(state: AnalyticsState): boolean {
    return state.isSpeedError;
  },

  getPeakPeriod(state: AnalyticsState): Record<string, unknown> {
    return    { 
                "peakTime": state.currentServiceRateData.initialData.peakTime,
                "peakNumber": state.currentServiceRateData.initialData.peakNumber
              }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

