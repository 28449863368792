export default {
  currentMode: "current",
  speed: {
    speedError: "Speed mode isn't available",
    errorDescription: "Please add the \"shape_dist_traveled\" field in the \"shapes.txt\" file to enable this mode",
  },
  headway: {
    headwayError: "Headway mode isn't available",
    errorDescription: "No headway data available.",
  }
}