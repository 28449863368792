import { Request } from '@/utils/request';
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { URL_G2VIZ_SERVICES } from '@/constants/config';
import { analyticsPath } from '@/utils/constant/path';

class Mobility extends Request {
  constructor() {
    super(URL_G2VIZ_SERVICES);
  }

  async getJsonUrl(url: string, method: Method = "GET", data = {}): Promise<any> {
    const res = await this.requestWithAxios
      .request({
        url,
        data,
        method
      });
    return res;
  }

  async fetchServiceRate(filePath: string) : Promise<any> {
    const requestPath = analyticsPath.serviceRate+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 

  async fetchSpeed(filePath: string) : Promise<any> {
    const requestPath = analyticsPath.speed+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 

  async fetchHeadway(filePath: string) : Promise<any> {
    const requestPath = analyticsPath.headway+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 
}

export default new Mobility();