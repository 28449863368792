export const URL_G2VIZ_SERVICES = process.env.VUE_APP_G2VIZ_SERVIES_URL_PROD

export const CHUNK_DURATION = 21600
export const UPLOAD_CHUNK_SIZE = 1024 * 1024 / 2 // 1/2MB
export const MIN_TIME = 0
export const MAX_TIME = 86400
export const MAX_FILE_SIZE = 1024 * 1024 * 15 // 15MB

export const INI_MAP = {
  ZOOM: 10,
  MIN_ZOOM: 2,
  MAX_ZOOM: 15,
  PITCH: 0,
  BEARING: 0,
  COLOR: [255, 115, 75],
  COLOR_DARK_MODE: [255, 103, 0],
  OPACITY: 0.6,
  MIN_PIXEL: 2,
  TRAIL_LENGTH: 100, 
};

export const INI_STOP = {
  OPACITY: 0.8,
  SCALES: 6,
  MIN_PIXELS: 1,
  MAX_PIXELS: 100,
  LINE_WIDTH: 1,
  COLOR: [0, 122, 255],
  COLOR_DARK_MODE: [0, 194, 255],
  ICON_SIZE: 2,
  ICON_SCALE: 20,
}

export const INI_ROUTE = {
  WIDTH_SCALES: 10,
  WIDTH_MAX: 10,
  WIDTH_MIN: 1,
  WIDTH: 3,
  HIGHTLIGHTED_WIDTH: 8,
  COLOR: [70, 70, 70, 80],
  COLOR_DARK_MODE: [240, 240, 240, 96],
  FIRST_ROUTE_COMPARE_COLOR: [240, 177, 17, 30],
  SECOND_ROUTE_COMPARE_COLOR: [76, 177, 217, 30]
}