const defaultState = () => ({
  currentTheme: "",
});

const state = defaultState();

export type ThemeState = typeof state;

const mutations = {
  SET_THEME(state: ThemeState, payload: string): void {
    state.currentTheme = payload;
  }, 
};

const getters = {
  isThemeDark(state: ThemeState): boolean {
    return state.currentTheme === 'dark';
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};

