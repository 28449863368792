import { Commit } from "vuex";
import { uploadResult } from "@/constants/type/outcome";
import { errorType } from "@/constants/type/error";
import { fileType } from "@/constants/type/upload";
import Upload from "@/services/Upload";
import { setStorage } from "@/helper/storage.helper";

const defaultState = () => ({
  currentUploadResult: [] as Array<uploadResult>,
  filePath: "",
  measurement: "",
  isMeasurementSelected: false,
  isAbleToNextState: false,
});

const state = defaultState();

export type UploadState = typeof state;

const mutations = {
  SET_CURRENT_UPLOAD_RESULT(state: UploadState, payload: Array<uploadResult>): void {
    state.currentUploadResult = payload;
  },
  SET_FILE_PATH(state: UploadState, payload: string): void {
    state.filePath = payload;
  },
  SET_MEASUREMENT(state: UploadState, payload: string): void {
    state.measurement = payload;
  },
  SET_IS_MEASUREMENT_SELECTED(state: UploadState, payload: boolean): void {
    state.isMeasurementSelected = payload;
  },
  SET_IS_ABLE_TO_NEXT_STATE(state: UploadState, payload: boolean): void {
    state.isAbleToNextState = payload;
  }
};

const actions = {
  async fetchUploadStatusResult({ commit }: { commit: Commit }, reqPath: string): Promise<any> {
    try {
      let result = [] as Array<uploadResult>
      const res = await Upload.fetchUploadStatus(reqPath);
      const uploadResponse = res.data;

      commit("SET_FILE_PATH", uploadResponse.payload.path_file);


      if (uploadResponse.status == "success") {
        let uploadResult = {} as uploadResult;

        uploadResult.errorCode = 0;
        uploadResult.isError = false;
        uploadResult.message = "success";
        uploadResult.optionalFile = uploadResponse.payload.missing_optional_files;

        result.push(uploadResult);

      } else {

        uploadResponse.error.forEach( (errorList: any) => {
          if(errorList.code === errorType.missingColumns) {
            let filename = [] as any;
            let columns = [] as any;

            errorList.payload.missing.forEach ( (missing: any) => {
              let uploadResult = {} as uploadResult;
              
              uploadResult.isError = true;
              uploadResult.errorCode = errorList.code;
              uploadResult.message = errorList.message;
              uploadResult.filename = missing.file;
              uploadResult.payload = missing.columns;
              
              result.push(uploadResult);
            })
          } else {
            errorList.payload.file.forEach ( (errorFile: any) => {
              let uploadResult = {} as uploadResult;

              uploadResult.isError = true;
              uploadResult.errorCode = errorList.code;
              uploadResult.message = errorList.message;

              let fileList = [];

              fileList.push(errorFile);
              uploadResult.filename = errorFile;
              uploadResult.payload = fileList;
              
              result.push(uploadResult);
            })
          }
        })
      }
      commit("SET_CURRENT_UPLOAD_RESULT", result);
      return result;
    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchReuploadStatusResult({ commit }: { commit: Commit }, data: any): Promise<any> {
    try {
      const res = await Upload.fetchReuploadStatus(data.reqPath);
      const reuploadResponse = res.data;
      if(reuploadResponse.status === "success") {
        // drop error
        const results = state.currentUploadResult.filter(result => {
          if (result.filename === fileType.CALENDAR_OR_CALENDAR_DATES) {
            return data.validatedFile !== fileType.CALENDAR && data.validatedFile !== fileType.CALENDAR_DATES;
          } else {
            return result.filename !== data.validatedFile;
          }
        });

        commit("SET_CURRENT_UPLOAD_RESULT", results);
        return results;
      } else {
        // drop previous error first
        const idx = state.currentUploadResult.findIndex(find => 
          find.filename === data.validatedFile
        );
        let results = state.currentUploadResult;

        // push the new error
        const reuploadResult = {} as uploadResult;
        const errorList = reuploadResponse.error[0];
        reuploadResult.isError = true;
        reuploadResult.errorCode = errorList.code;
        reuploadResult.message = errorList.message;

        if (errorList.code === errorType.missingColumns) {
          errorList.payload.missing.forEach ((missing: any) => {
            reuploadResult.filename = missing.file;
            reuploadResult.payload = missing.columns;
            results.splice(idx, 1, reuploadResult);
          })

        } else {
          errorList.payload.file.forEach ((errorFile: any) => {
            let fileList = [];
            fileList.push(errorFile);
            reuploadResult.filename = errorFile;
            reuploadResult.payload = fileList;
            results.splice(idx, 1, reuploadResult);
          })
        }

        commit("SET_CURRENT_UPLOAD_RESULT", results);
        return results;
      }
    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchUploadChunkResult({ commit }: { commit: Commit }, uploadedFile: File): Promise<any> {
    try {
      const res = await Upload.uploadFileChunk(uploadedFile);
      const uploadResponse = res.data;
      const filePath = uploadResponse.payload.file_path;
      
      commit("SET_FILE_PATH", filePath);
      setStorage('filePath', filePath);
      return filePath;

    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchReuploadChunkResult({ commit }: { commit: Commit }, uploadedFile: File): Promise<any> {
    try {
      const res = await Upload.reuploadFileChunk(uploadedFile);
      const uploadResponse = res.data;

      return uploadResponse;

    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchDeletDirectoryResult({ commit }: { commit: Commit }): Promise<any> {
    try {
      const res = await Upload.deleteDirectory(state.filePath);

      return res;
    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  }
};

const getters = {
  getCurrentUploadResult(state: UploadState): any {
    return state.currentUploadResult;
  },
  getStateAccessible(state: UploadState): any {
    return state.isAbleToNextState;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

