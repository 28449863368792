import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_screen = _resolveComponent("loading-screen")!
  const _component_modal_something_wrong = _resolveComponent("modal-something-wrong")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "app",
    class: "app",
    style: _normalizeStyle({ backgroundColor: _ctx.bgColor })
  }, [
    (_ctx.isUploading)
      ? (_openBlock(), _createBlock(_component_loading_screen, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isSomethingWrong)
      ? (_openBlock(), _createBlock(_component_modal_something_wrong, {
          key: 1,
          "custom-style-modal": `width: 500px;`
        }))
      : _createCommentVNode("", true),
    (!_ctx.isUploading)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 2 }))
      : _createCommentVNode("", true)
  ], 4))
}