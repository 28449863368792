import { ActionContext, Commit, Dispatch } from "vuex";
import { RootState } from "@/store";
import { routesData, routeDetails, routeDetailsStopsData, topRoutesData } from "@/constants/type/routes";
import { DAY_LIST } from "@/constants/require";
import Routes from "@/services/Routes";

const defaultState = () => ({
  routesData: [] as Array<routesData>,
  routeLists: [] as Array<routesData>,
  topRouteLists: [] as Array<topRoutesData>,
  routesDetails: {} as routeDetails,
  stopsData: [] as Array<routeDetailsStopsData>,
  isRouteFocused: false,
  isCompareMode: false,
  isShowFirstServices: true,
  isShowSecondServices: true,
  focusedRouteID: "",
  focusedRouteName: "",
  isLoadingRouteDetails: false,
});

const state = defaultState();

export type RoutesState = typeof state;

const mutations = {
  SET_ROUTES_DATA(state: RoutesState, payload: Array<routesData>): void {
    state.routesData = payload;
  },

  SET_ROUTES_LISTS(state: RoutesState, payload: Array<routesData>): void {
    state.routeLists = payload;
  },

  SET_TOP_ROUTES_LISTS(state: RoutesState, payload: Array<topRoutesData>): void {
    state.topRouteLists = payload;
  },

  SET_ROUTE_DETAILS(state: RoutesState, payload: routeDetails): void {
    state.routesDetails = payload;
  },

  SET_STOPS_DATA(state: RoutesState, payload: Array<routeDetailsStopsData>): void {
    state.stopsData = payload;
  },

  SET_IS_ROUTE_FOCUSED(state: RoutesState, payload: boolean): void {
    state.isRouteFocused = payload;
  },

  SET_IS_COMPARE_MODE(state: RoutesState, payload: boolean): void {
    state.isCompareMode = payload;
  },

  SET_IS_SHOW_FIRST_SERVICES(state: RoutesState, payload: boolean): void {
    state.isShowFirstServices = payload;
  },

  SET_IS_SHOW_SECOND_SERVICES(state: RoutesState, payload: boolean): void {
    state.isShowSecondServices = payload;
  },

  SET_FOCUSED_ROUTE_ID(state: RoutesState, payload: string): void {
    state.focusedRouteID = payload;
  },

  SET_FOCUSED_ROUTE_NAME(state: RoutesState, payload: string): void {
    state.focusedRouteName = payload;
  },

  SET_IS_LOADING_ROUTE_DETAILS(state: RoutesState, payload: boolean): void {
    state.isLoadingRouteDetails = payload;
  },

  RESET_FOCUSED_ROUTE(state: RoutesState): void {
    state.isRouteFocused = false;
    state.focusedRouteID = "";
    state.isLoadingRouteDetails = false;
  }
};

const getters = {
  getRouteLists(state: RoutesState): Array<any> {
    const formattedLists = state.routeLists.map((routeDetail: any) => {
      let routeLabel = "";
      if (routeDetail.route_short_name !== "" && routeDetail.route_long_name !== "") {
        routeLabel = routeDetail.route_short_name+" | "+routeDetail.route_long_name;
      } else if (routeDetail.route_short_name == "" && routeDetail.route_long_name !== "") {
        routeLabel = routeDetail.route_long_name;
      } else if (routeDetail.route_short_name !== "" && routeDetail.route_long_name == "") {
        routeLabel = routeDetail.route_short_name;
      } else {
        routeLabel = "route name is not provided";
      }
      return {"routeID": routeDetail.route_id, "routeLabel": routeLabel}
    })
    return formattedLists;
  },

  getTopRouteLists(state: RoutesState): Array<topRoutesData> {
    return state.topRouteLists;
  },

  getRouteDetails(state: RoutesState): routeDetails {
    return state.routesDetails;
  },

  getStopsData(state: RoutesState): Array<routeDetailsStopsData> {
    return state.stopsData;
  },

  isShowRouteDetails(state: RoutesState): boolean {
    return state.isRouteFocused;
  },

  isRouteDetailsLoading(state: RoutesState): boolean {
    return state.isLoadingRouteDetails;
  },

  isCompareRoutes(state: RoutesState): boolean {
    return state.isCompareMode;
  },

  isShowFirstServices(state: RoutesState): boolean {
    return state.isShowFirstServices;
  },

  isShowSecondServices(state: RoutesState): boolean {
    return state.isShowSecondServices;
  },

};

const actions = {

  async fetchAllRoutesResult({ commit, rootState }: ActionContext<PermissionState, RootState>): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const dateStr = rootState.mobility.currentDate;
      const requestPath = filePath+"/"+dateStr;

      const res = await Routes.fetchRoutes(requestPath);
      const routesData = res.data.payload.routesData as Array<routesData>;
      const routeLists = routesData.filter((value, index, self) => self.findIndex(value2 =>(value2.route_id === value.route_id)) === index);
      const topRouteLists = res.data.payload.topRouteLists;


      commit("SET_ROUTES_DATA", routesData);
      commit("SET_ROUTES_LISTS", routeLists);
      commit("SET_TOP_ROUTES_LISTS", topRouteLists);

      return routesData;
    } catch(error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchCompareRoutesResult({ commit, rootState }: ActionContext<PermissionState, RootState>, compareDate: string): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const requestPath = filePath+"/"+compareDate;

      const res = await Routes.fetchRoutes(requestPath);
      const routesData = res.data.payload.routesData as Array<routesData>;

      return routesData;
    } catch(error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchRouteDetailsResult({ commit, rootState }: ActionContext<PermissionState, RootState>, routeID: string): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const dateStr = rootState.mobility.currentDate;
      const requestPath = filePath+"/"+dateStr+"/"+routeID;

      const res = await Routes.fetchRouteDetails(requestPath);
      const data = res.data.payload;

      const serviceDayData = data.serviceDay;
      let cont_flag = false;
      let inRow = 0;
      let serviceDay = "";

      // convert service_day array to formatted label 
      for(let i = 0; i < serviceDayData.length; i++){
        if (cont_flag) {
          if (serviceDayData[i] === 0) {
            if (inRow > 2) {
              serviceDay = serviceDay + " - " + DAY_LIST[i-1];
              cont_flag = false;
              inRow = 0;
            } else if (inRow > 1) {
              serviceDay = serviceDay + ", " + DAY_LIST[i-1];
              cont_flag = false;
              inRow = 0;
            } else {
              cont_flag = false;
              inRow = 0;
            }
          } else {
              if(i === serviceDayData.length - 1) {
                if (inRow > 2) {
                    serviceDay = serviceDay + " - " + DAY_LIST[i];   
                } else {
                    serviceDay = serviceDay + ", " + DAY_LIST[i];   
                }
                cont_flag = false;
                inRow = 0;
              } else {
                inRow += 1;
              }
          }  
        } else {
          if (serviceDayData[i] === 1) {
            if (serviceDay.length === 0) {
              serviceDay = serviceDay + DAY_LIST[i];  
            } else {
              serviceDay = serviceDay + ", " + DAY_LIST[i];
            }
            cont_flag = true;
            inRow += 1;
          }
        }
      }

      let routeDetails = {} as routeDetails;
      routeDetails.routesData = data.routesData;
      routeDetails.serviceDay = serviceDay;
      routeDetails.exceptionService = data.exceptionService;

      const stopsData = data.stopsData;

      commit("SET_ROUTE_DETAILS", routeDetails);
      commit("SET_STOPS_DATA", stopsData );

      return ;
    } catch(error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};


