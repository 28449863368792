export default {
  currentMode: "current",
  speed: {
    speedError: "Speed mode isn't available",
    errorDescription: "Please add the field \"shape_dist_traveled\" in the file \"shapes.txt\" to enable this mode",
  },
  headway: {
    headwayError: "Headway mode isn't available",
    errorDescription: "No headway data available.",
  }
}