
import { defineComponent } from 'vue';
import { deleteStorage, getStorage, setStorage } from './helper/storage.helper';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { uploadPath } from '@/utils/constant/path';
import LoadingScreen from '@/components/molecules/LoadingScreen.vue';
import ModalSomethingWrong from '@/components/molecules/ModalSomethingWrong.vue';
import axios from 'axios';

export default defineComponent({
  name: 'App',
  data() {
    return {
      bgColor: "#F8F8F8",
    }
  },
  components: {
    LoadingScreen,
    ModalSomethingWrong,
  },
  computed: {
    ...mapGetters("loading", ["isUploading", "isSomethingWrong"]),

    isThemeDark(): boolean {
      if (getStorage("theme") === "dark") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations("theme", ["SET_THEME"]),
    ...mapActions("upload", ["fetchDeletDirectoryResult"]),

    beforeUnloadHandler(e: any) {

      const filePath = getStorage("filePath");
      
        if(filePath !== null) {
          const requestPath = uploadPath.deleteDir+"/"+filePath;
          deleteStorage("filePath");
          axios.delete(requestPath)
            .catch(error => {
              // Handle any errors
              throw error;
            });
        }
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeUnloadHandler);
  },
  mounted() {
    if (getStorage("theme") === null) {
      setStorage("theme", "light");
      this.SET_THEME("light");
    } else {
      this.SET_THEME(getStorage("theme"));
    }
  },  
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler);
  },
  watch: {
    "$store.state.theme.currentTheme"(newTheme) {
      if (newTheme === "dark") {
        this.bgColor = "#1B1C1E";
      } else {
        this.bgColor = "#F8F8F8";
      }
    },
  },  

});
