const defaultState = () => ({
  isUploading: false,
  isLoading: false,
  isMapLoading: false,
  isChartLoading: false,
  isSomethingWrong: false,
});

const state = defaultState();

export type LoadingState = typeof state;

const mutations = {
  SET_UPLOADING(state: LoadingState, payload: boolean): void {
    state.isUploading = payload;
  }, 

  SET_LOADING(state: LoadingState, payload: boolean): void {
    state.isLoading = payload;
  },

  SET_MAP_LOADING(state: LoadingState, payload: boolean): void {
    state.isMapLoading = payload;
  },

  SET_CHART_LOADING(state: LoadingState, payload: boolean): void {
    state.isChartLoading = payload;
  }, 

  SET_IS_SOMETHING_WRONG(state: LoadingState, payload: boolean): void {
    state.isSomethingWrong = payload;
  }, 
};

const getters = {
  isUploading(state: LoadingState): boolean {
    return state.isUploading;
  },

  isLoading(state: LoadingState): boolean {
    return state.isLoading;
  },

  isSomethingWrong(state: LoadingState): boolean {
    return state.isSomethingWrong;
  },

  isMapLoading(state: LoadingState): boolean {
    return state.isMapLoading;
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  getters
};

