import { createStore } from "vuex";

/*
 * Set dynamic module
 */
const modules: Record<string, any> = {};
const files = require.context("./modules", true, /[A-Za-z0-9-_,\s]+\.ts$/i);

const rootState: Record<string, any> = {};

files.keys().forEach((key) => {
  const name = key.split(/[./]/)[2];
  modules[name] = files(key).default;
  rootState[name] = typeof modules[name];
});

export type RootState = typeof rootState;

const store = createStore({
  modules,
});

export default store;
