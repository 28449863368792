import { Request } from '@/utils/request';
import axios, { AxiosError, AxiosResponse, Method } from "axios";
import { URL_G2VIZ_SERVICES } from '@/constants/config';
import { mobilityPath } from '@/utils/constant/path';

class Mobility extends Request {
  constructor() {
    super(URL_G2VIZ_SERVICES);
  }

  async getJsonUrl(url: string, method: Method = "GET", data = {}): Promise<any> {
    const res = await this.requestWithAxios
      .request({
        url,
        data,
        method
      });
    return res;
  }

  async initialMobility(filePath: string) : Promise<any> {
    const requestPath = mobilityPath.initial+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 

  async fetchMobility(filePath: string) : Promise<any> {
    const requestPath = mobilityPath.fetch+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 

  async fetchChunkMobility(filePath: string) : Promise<any> {
    const requestPath = mobilityPath.fetchWithDuration+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 

  async fetchTimestamps(filePath: string) : Promise<any> {
    const requestPath = mobilityPath.fetchTimestamps+'/'+filePath;
    return await this.getJsonUrl(
      requestPath,
      "GET",
    );
  } 
}

export default new Mobility();