
import { defineComponent } from 'vue';
import { routerConfig } from '@/constants/router';
import { setStorage } from '@/helper/storage.helper';
import { mapGetters, mapMutations } from 'vuex';

export default defineComponent({
  name: 'Navbar',
  data() {
    return {
      isLight: false,
      isHomePage: false,
    }
  },
  computed: {
    ...mapGetters("loading", ["isUploading"]),

    isThemeDark(): boolean {
      if (this.$store.state.theme.currentTheme === "dark") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations("theme", ["SET_THEME"]),

    backToHome(): void {
      this.$router.push(routerConfig.home.path).then(() => { location.reload(); });
    },

    toggleTheme(): void {
      if (this.$store.state.theme.currentTheme === "light") {
        this.SET_THEME("dark");
        setStorage("theme", "dark");
      } else {
        this.SET_THEME("light");
        setStorage("theme", "light");
      }
    },

    aboutAction(): void {
      this.$emit('aboutAction');
    },
  },

  mounted() {
    if(this.$store.state.theme.currentTheme === "dark") {
      this.isLight = true;
    }
    this.isHomePage = this.$route.path === '/';
  },
});
