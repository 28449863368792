import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65e1edef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_lottie_animation = _resolveComponent("lottie-animation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_navbar),
    _createElementVNode("div", {
      class: _normalizeClass(["loading", _ctx.isThemeDark ? 'dark': ''])
    }, [
      _createVNode(_component_lottie_animation, {
        class: "animator",
        ref: "anim",
        animationData: require('@/assets/animation/map_loading.json'),
        loop: true
      }, null, 8, ["animationData"]),
      _createElementVNode("div", {
        class: _normalizeClass(["text", _ctx.isThemeDark ? 'dark': ''])
      }, _toDisplayString(_ctx.$t('loading.mapLoading')), 3)
    ], 2)
  ]))
}