import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from 'axios';

axios.defaults.headers.post["Content-Type"] = "application/json";

export class Request {
  requestWithAxios: AxiosInstance;

  constructor(url: string | undefined) {
    this.requestWithAxios = axios.create({ baseURL: url });
  }

  requestAPI(
    url: string,
    method: Method = "GET",
    data = {},
    header: Record<string, string> = {}
  ): Promise<any> {
    const headers = { ...header };

    return this.requestWithAxios
      .request({
        url,
        data,
        method,
        headers,
      })
      .then(({ data }: AxiosResponse) => data)
      .catch((error: AxiosError) => {
          throw error;
      });
  }
}

