export default {
  header: {
    title: "visualizing",
    subTitle: "traffic feed",
  },

  slider: {
    time: {
    },
    speed: {
      header: "speed adjustment",
      min: "1",
      max: "10",
    }
  },

  peakPeriod: "Peak Time",
  peakNumberUnit: "services",

  stopsMode: {
    serviceRoutes: "service routes",
    selectStop: "select stop",
    tripHeader: {
      route: "route",
      headsign: "trip headsign",
      arrivalTime: "arrival time",
      headway: "headway", 
      minute: "(min)",
    },
    topLists: {
      title: "Top 10 busiest stations",
      headers: {
        code: "Stop Code/ID",
        name: "Stop Name",
        number: "Number of Services",
      }
    }
  },

  routesMode: {
    selectRoute: "select route",
    routeColor: "route color definitions",
    routesCompare: {
      title: "comparison",
      firstService: "first service date",
      secondService: "second service date", 
      firstColor: "the route belongs to the first service date",
      secondColor: "the route belongs to the second service date",
      mixedColor: "the route belongs to both service dates",
      selectFirstDate: "select first service date",
      selectSecondDate: "select second service date",
    },
    stopHeader: {
      name: "Stop Name",
      stopCode: "Stop Code/ID",
    },
    topLists: {
      title: "Top 10 busiest routes",
      headers: {
        shortName: "Short Name",
        name: "Route Name",
        number: "Number of Trips",
      }
    }
  }
}