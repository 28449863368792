import { ActionContext, Commit, Dispatch } from "vuex";
import { RootState } from "@/store";
import { currentTimeResult } from "@/constants/type/outcome";
import { errorType } from "@/constants/type/error";
import { fileAccepted } from "vue3-dropzone/dist/useDropzone";
import Mobility from "@/services/Mobility";
import { initialMobilityData, timestamps, timestampsChunk } from "@/constants/type/mobility";
import { getStorage } from "@/helper/storage.helper";
import { CHUNK_DURATION, MAX_TIME } from "@/constants/config";
import dayjs from 'dayjs';

const defaultState = () => ({
  currentTime: 0,
  currentSpeed: 50,
  currentDate: "2023/01/01",
  chunkTime: 0,
  initialData: {} as initialMobilityData,
  currentTimestamps: [] as Array<timestamps>,
  mobilityData: [4] as Array<any>,
  currentMapState: 0,
  isMapRunning: false,
});

const state = defaultState();

export type MobilityState = typeof state;

const mutations = {
  RESET_CURRENT_TIME(state: MobilityState): void {
    state.currentTime = 0;
  },
  RESET_CHUNK_TIME(state: MobilityState): void {
    state.chunkTime = 0;
  },
  SET_CURRENT_TIME(state: MobilityState, payload: number): void {
    state.currentTime = payload;
  },
  SET_CURRENT_SPEED(state: MobilityState, payload: number): void {
    state.currentSpeed = payload;
  },
  SET_CURRENT_DATE(state: MobilityState, payload: string): void {
    state.currentDate = payload;
  },
  SET_INITIAL_DATA(state: MobilityState, payload: initialMobilityData): void {
    state.initialData = payload;
  },
  SET_CURRENT_TIMESTAMPS(state: MobilityState, payload: Array<timestamps>): void {
    state.currentTimestamps = payload;
  },
  SET_MOBILITY_DATA(state: MobilityState, payload: any): void {
    state.mobilityData[payload.index] = payload.payloadData;
  },
  SET_CHUNK_TIME(state: MobilityState, payload: number): void {
    state.chunkTime = payload;
  },
  SET_CURRENT_MAP_STATE(state: MobilityState, payload: number): void {
    state.currentMapState = payload;
  },
  SET_IS_MAP_RUNNING(state: MobilityState, payload: boolean): void {
    state.isMapRunning = payload;
  },
};

const getters = {
  getCurrentTime(state: MobilityState): number {
    return state.currentTime;
  },
  getCurrentSpeed(state: MobilityState): number {
    return state.currentSpeed;
  },
  getCurrentTimeWithFormat(state: MobilityState): currentTimeResult {
    const time = state.currentTime;
    let format = {} as currentTimeResult;
    if(Math.floor(time/3600) < 10) {
      format.hour = "0" + Math.floor(time/3600).toString();
    } else {
      format.hour = Math.floor(time/3600).toString();
    }

    if(Math.floor((time%3600)/60) < 10) {
      format.minute = "0" + Math.floor((time%3600)/60).toString();
    } else {
      format.minute = Math.floor((time%3600)/60).toString();
    }

    if(Math.floor((time%3600)%60) < 10) {
      format.second = "0" + Math.floor((time%3600)%60).toString();
    } else {
      format.second = Math.floor((time%3600)%60).toString();
    }

    return format;
  },

  getPathCoordinates(state: MobilityState): Array<object> {
    return state.initialData.path;
  },

  getStartDate(state: MobilityState): Date {
    return new Date(state.initialData.startDate);
  },

  getEndDate(state: MobilityState): Date {
    return new Date(state.initialData.endDate);
  },

  getNextDate(state: MobilityState): string {
    const currentDate = dayjs(state.currentDate);
    const nextDate = currentDate.add(1, "day").toISOString().substring(0,10);
    return nextDate;
  },

  getMobilityData(state: MobilityState): Array<any> {
    return state.mobilityData;
  },

  isMapRunning(state: MobilityState): boolean {
    return state.isMapRunning;
  },
};

const actions = {

  async fetchInitialMobilityResult({ commit, rootState }: ActionContext<PermissionState, RootState>): Promise<any> {
    try {
      const requestPath = rootState.upload.filePath;
      const res = await Mobility.initialMobility(requestPath);
      const data = res.data.payload;
      let initialMobilityData = {} as initialMobilityData;
      initialMobilityData.agencyName = data.agency_name;
      initialMobilityData.endDate = data.end_date;
      initialMobilityData.startDate = data.start_date;
      initialMobilityData.exceptionDate = data.exception_date;
      initialMobilityData.initialLon = data.initial_lon;
      initialMobilityData.initialLat = data.initial_lat;
      initialMobilityData.path = data.path;

      commit("SET_INITIAL_DATA", initialMobilityData);
      commit("SET_CURRENT_DATE", data.start_date);

      return initialMobilityData;
    } catch(error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchMobilityChunkWithDurationResult({ commit, rootState }: ActionContext<PermissionState, RootState>): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const shapes = state.initialData.path;
      
      let start = 0;
      let idx = 0;

      while (start < MAX_TIME) {
        const dateStr = state.currentDate as string;
        const timeStr = start.toString();
        const durationStr = CHUNK_DURATION.toString();
        const requestPath = filePath+"/"+dateStr+"/"+timeStr+"/"+durationStr;
  
        const res = await Mobility.fetchChunkMobility(requestPath);
        const timestampData = res.data.payload.timestamps;
        
        let mobilityChunkData = [];

        for(let i=0; i<timestampData.length; i++) {
          mobilityChunkData.push({
           ...timestampData[i], 
           ...(shapes.find((shape: any) => shape.shape_id === timestampData[i].shape_id))}
          );
        }
    
        commit("SET_MOBILITY_DATA", {payloadData: mobilityChunkData, index: idx});
        
        idx += 1;
        start += CHUNK_DURATION;
      }
      
      return state.mobilityData;

    } catch (error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchMobilityTimestampsResult({ commit, rootState }: ActionContext<PermissionState, RootState>): Promise<any> {
      try {
        const filePath = rootState.upload.filePath;
        const dateStr = state.currentDate as string;
        const requestPath = filePath+"/"+dateStr;
        const res = await Mobility.fetchTimestamps(requestPath);
        const mobilityChunkData = res.data.payload.timestamps;

        commit("SET_CURRENT_TIMESTAMPS", mobilityChunkData);

        return mobilityChunkData;
      } catch (error: any) {
        commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
        throw error;
      }
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

