export const getStorage = (name: string): string | null =>
  localStorage[name] || null;

export const setStorage = (name: string, payload: string): void => {
  localStorage[name] = payload;
};

export const deleteStorage = (name: string): void => {
  delete localStorage[name];
};
