export const REQUIRED_FILES = [
  "agency.txt",
  "stops.txt",
  "routes.txt",
  "trips.txt",
  "stop_times.txt",
  "shapes.txt",
  "calendar.txt or calendar_dates.txt or both"
];

export const FILE_LISTS = [
  "agency.txt",
  "shapes.txt",
  "stops.txt",
  "routes.txt",
  "trips.txt",
  "stop_times.txt",
  "calendar.txt",
  "calendar_dates.txt"
];

export const MEASUREMENTS = [
  "Meter",
  "Kilometer",
  "Miles",
  "Yard",
  "none"
]

export const CITY_LIST = [
  {
    label: 'SFMTA, San Francisco',
    link: 'sanfran',
    unit: 'Meter',
  },
  {
    label: 'Cercanías Madrid, Madrid',
    link:'madrid',
    unit: 'Meter',
  },
  {
    label: 'UTA GTFS, Utah',
    link: 'salt_lake',
    unit: 'Kilometer',
  }
];

export const DAY_LIST = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];