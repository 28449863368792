
import { defineComponent } from 'vue';

export default defineComponent({
  name: "CustomModal",
  props: {
    customStyleModal: {
      type: String,
      required: false,
      default: "",
    },
    isModalClosed: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    isThemeDark(): boolean {
      if (this.$store.state.theme.currentTheme === "dark") {
        return true;
      } else {
        return false;
      }
    },
  },
});
