export default {
  file: {
    type: "Please make sure the uploaded file is a .zip file",
    size: "File size exceeds the system limit (10MB). Please try again."
  },
  sthWrong: {
    header: "Warning",
    description: "Something went wrong. Please try again.",
    button: "close",
  }
}