import { createApp } from 'vue'
import store from '@/store/index'
import router from '@/router/index'
import App from './App.vue';
import i18n from './utils/plugin/i18n.config'
import LottieAnimation from 'lottie-web-vue';
import Datepicker from '@vuepic/vue-datepicker';
import VCalendar from 'v-calendar';
import VueSelect from 'vue-select';
import FloatingVue from 'floating-vue'
import '@/assets/styles/global.scss';
import 'v-calendar/dist/style.css';
import 'vue-slider-component/theme/default.css';
import 'vue-select/dist/vue-select.css';
import 'floating-vue/dist/style.css';

createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(LottieAnimation)
  .use(VCalendar, {})
  .use(FloatingVue)
  .component('datepicker', Datepicker)
  .component('v-select', VueSelect)
  .mount('#app')
