import { ActionContext, Commit, Dispatch } from "vuex";
import { RootState } from "@/store";
import Stops from "@/services/Stops";
import { stopLists, stopDetails, stopServices, topStopLists } from "@/constants/type/stops";
import { number } from "@intlify/core-base";

const defaultState = () => ({
  stopLists: [] as Array<stopLists>,
  topStopLists: [] as Array<topStopLists>,
  stopDetails: {} as stopDetails,
  stopServices: [] as Array<stopServices>,
  isStopFocused: false,
  focusedStopID: "",
  focusedStopName: "",
  isLoadingStopDetail: false,
});

const state = defaultState();

export type StopsState = typeof state;

const mutations = {
  SET_STOP_LISTS(state: StopsState, payload: Array<stopLists>): void {
    state.stopLists = payload;
  },

  SET_TOP_STOP_LISTS(state: StopsState, payload: Array<topStopLists>): void {
    state.topStopLists = payload;
  },

  SET_STOP_DETAILS(state: StopsState, payload: stopDetails): void {
    state.stopDetails = payload;
  },

  SET_STOP_SERVICES(state: StopsState, payload: Array<stopServices>): void {
    state.stopServices = payload;
  },

  SET_IS_STOP_FOCUSED(state: StopsState, payload: boolean): void {
    state.isStopFocused = payload;
  },

  SET_FOCUSED_STOP_ID(state: StopsState, payload: string): void {
    state.focusedStopID = payload;
  },

  SET_FOCUSED_STOP_NAME(state: StopsState, payload: string): void {
    state.focusedStopName = payload;
  },

  SET_IS_LOADING_STOP_DETAILS(state: StopsState, payload: boolean): void {
    state.isLoadingStopDetail = payload;
  },

  RESET_FOCUSED_STOP(state: StopsState): void {
    state.isStopFocused = false;
    state.focusedStopID = "";
    state.focusedStopName = "select stop";
    state.isLoadingStopDetail = false;
  }
};

const getters = {
  getStopLists(state: StopsState): Array<stopLists> {
    return state.stopLists;
  },

  getTopStopLists(state: StopsState): Array<topStopLists> {
    return state.topStopLists;
  },

  getStopDetails(state: StopsState): stopDetails {
    return state.stopDetails;
  },

  getStopServices(state: StopsState): Array<stopServices> {
    return state.stopServices;
  },

  isShowStopDetail(state: StopsState): boolean {
    return state.isStopFocused
  },

  isStopDetailLoading(state: StopsState): boolean {
    return state.isLoadingStopDetail;
  }
};

const actions = {

  async fetchAllStopsResult({ commit, rootState }: ActionContext<PermissionState, RootState>): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const dateStr = rootState.mobility.currentDate;
      const requestPath = filePath+"/"+dateStr;

      const res = await Stops.fetchStops(requestPath);
      const stopsData = res.data.payload.stopsData;
      const topStopData = res.data.payload.topStopLists;

      commit("SET_STOP_LISTS", stopsData);
      commit("SET_TOP_STOP_LISTS", topStopData);

      return stopsData;
    } catch(error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

  async fetchStopDetailResult({ commit, rootState }: ActionContext<PermissionState, RootState>, stopID: string): Promise<any> {
    try {
      const filePath = rootState.upload.filePath;
      const dateStr = rootState.mobility.currentDate;
      const requestPath = filePath+"/"+dateStr+"/"+stopID;

      const res = await Stops.fetchStopDetails(requestPath);
      const stopDetailsData = res.data.payload.stopsData;
      const stopServicesData = res.data.payload.servicesData;

      commit("SET_STOP_DETAILS", stopDetailsData);
      commit("SET_STOP_SERVICES", stopServicesData );

      return ;
    } catch(error: any) {
      commit("loading/SET_IS_SOMETHING_WRONG", true, { root: true });
      throw error;
    }
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};


