export enum fileType {
  AGENCY = "agency.txt",
  STOPS = "stops.txt",
  ROUTES = "routes.txt",
  TRIPS = "trips.txt",
  STOP_TIMES = "stop_times.txt",
  CALENDAR = "calendar.txt",
  CALENDAR_DATES = "calendar_dates.txt",
  SHAPES = "shapes.txt",
  CALENDAR_OR_CALENDAR_DATES = "calendar.txt or calendar_dates.txt",
}
