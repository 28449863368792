export const uploadPath = {
  upload: "/api/upload",
  uploadChunk: "/api/upload/chunk",
  uploadStatus: "/api/upload/status",
  reupload: {
    stops: "/api/upload/stops",
    routes: "/api/upload/routes",
    trips: "/api/upload/trips",
    stop_times: "/api/upload/stop_times",
    calendar: "/api/upload/calendar",
    calendar_dates: "/api/upload/calendar_dates",
    shapes: "/api/upload/shapes",
    chunk: "/api/reupload/chunk",
    status: "/api/reupload/status"
  },
  deleteDir: "/api/delete"
};

export const mobilityPath = {
  initial: "/api/mobility",
  fetch: "/api/mobility/chunk",
  fetchWithDuration: "/api/mobility/chunk_duration",
  fetchTimestamps: "/api/mobility/timestamps",
};

export const analyticsPath = {
  serviceRate: "/api/analytics/service_rate",
  speed: "/api/analytics/speed",
  headway: "/api/analytics/headway",
};

export const stopsPath = {
  base: "/api/stops",
};

export const routesPath = {
  base: "/api/routes",
};