export default {
  title: {
    warning: "Warning !",
    upload: "Upload Status",
  },
  button: {
    left: {
      cancel: "cancel",
      close: "close",
    },
    right: {
      next: "next",
      reupload: "reupload",
    }
  },
  upload: {
    header: {
      file: "file",
      status: "status",
    },
    status: {
      error: "error",
      success: "success",
      warningShapeFile: "select distance unit",
      optionalFile: "optional file",
    },
    error: {
      missingHeader: "missing :",
      typeError: " is not a .txt or .csv file",
      missingFile: "please reupload",
    },
    warning: {
      measurement: "please select \"shape_dist_traveled\" unit"
    }
  }
}

