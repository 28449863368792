export const routerConfig = {
  home: {
    path: "/",
    name: "Home",
  },
  visualize: {
    path: "/visualize",
    name: "Visualize",
  },
  test: {
    path: "/test",
    name: "test",
  }
}